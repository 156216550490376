<template>
  <!--begin::details View-->
  <loading
    :active="isLoading"
    :is-full-page="fullPage"
    :z-index="1100"
  ></loading>
  <template v-if="isLoaded && !error">
    <div class="card shadow mb-xl-8">
      <div
        class="d-block d-md-flex justify-content-between bg-light rounded-1 m-5 p-5"
      >
        <div class="d-flex">
          <label class="fw-bold text-gray-600">Paragrafo 1.A</label>
          <div class="ms-4 mt-1">
            <TextInput
              :disabled="readOnly"
              name="privacy_1"
              type="checkbox"
              :checked="privacy.privacy_1"
              :value="
                privacy.privacy_1 ? (privacy_1 = true) : (privacy_1 = false)
              "
              @change="privacy.privacy_1 = !privacy.privacy_1"
            />
          </div>
        </div>
        <div class="d-flex">
          <label class="fw-bold text-gray-600">Paragrafo 1.B</label>
          <div class="ms-4 mt-1">
            <TextInput
              :disabled="readOnly"
              name="privacy_2"
              type="checkbox"
              :value="
                privacy.privacy_2 ? (privacy_2 = true) : (privacy_2 = false)
              "
              :checked="privacy.privacy_2"
              @change="privacy.privacy_2 = !privacy.privacy_2"
            />
          </div>
        </div>
        <div class="d-flex">
          <label class="fw-bold text-gray-600">Paragrafo 1.C</label>
          <div class="ms-4 mt-1">
            <TextInput
              :disabled="readOnly"
              name="privacy_3"
              type="checkbox"
              :value="
                privacy.privacy_3 ? (privacy_3 = true) : (privacy_3 = false)
              "
              :checked="privacy.privacy_3"
              @change="privacy.privacy_3 = !privacy.privacy_3"
            />
          </div>
        </div>
        <div class="d-flex">
          <label class="fw-bold text-gray-600">Paragrafo 1.D</label>
          <div class="ms-4 mt-1">
            <TextInput
              :disabled="readOnly"
              name="privacy_4"
              type="checkbox"
              :checked="privacy.privacy_4"
              :value="
                privacy.privacy_4 ? (privacy_4 = true) : (privacy_4 = false)
              "
              @change="privacy.privacy_4 = !privacy.privacy_4"
            />
          </div>
        </div>
        <div class="d-flex">
          <label class="fw-bold text-gray-600">Paragrafo 1.E</label>
          <div class="ms-4 mt-1">
            <TextInput
              :disabled="readOnly"
              name="privacy_5"
              type="checkbox"
              :checked="privacy.privacy_5"
              :value="
                privacy.privacy_5 ? (privacy_5 = true) : (privacy_5 = false)
              "
              @change="privacy.privacy_5 = !privacy.privacy_5"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <!--  <div class="text-start">
          <button
            type="button"
            class="btn btn-light text-gray-700 fs-6"
            @click="stampaModulo()"
            :disabled="isLoading"
          >
            <i class="bi bi-download fs-6 text-gray-700"></i>scarica modulo
            consenso
          </button>
        </div> -->
      </div>
      <div v-if="!readOnly" class="col-md-6 text-end mb-xl-8 my-3">
        <span
          type="button"
          class="badge bg-secondary rounded blueFit fs-5 me-4"
          @click="editPrivacy()"
        >
          Salva Modifiche
        </span>
      </div>
    </div>
    <div class="card shadow mb-5 mb-xl-10">
      <div class="row bg-light rounded-1 m-5 p-5">
        <div
          class="row pb-4 font-italic text-gray-600 fst-italic text-jiustify"
        >
          <span class="pb-3">
            La Federazione Italiana Tennis e Padel, con sede in (00135) Roma,
            Stadio Olimpico - Curva Nord - Ingresso 44 - Scala G (la “FITP”), in
            qualità di Titolare del trattamento, desidera informarLa che ha
            necessità di procedere al trattamento dei Suoi dati personali.<br />
            La FITP ha nominato un Data Protection Officer, raggiungibile
            all’indirizzo sopra riportato e tramite pec a dpo@pec.federtennis.it
          </span>
          <span class="pb-3">
            <b>1. Finalità e base giuridica del trattamento.</b> I dati
            personali trattati sono quelli da Lei forniti in occasione della
            compilazione del modulo per il rilascio (o per il rinnovo) della
            tessera federale e quelli da Lei forniti nello svolgimento
            dell’attività sportiva o, comunque, dell’attività federale. Questi
            dati verranno trattati per le seguenti finalità: <br />
            A. consentire il rilascio (o il rinnovo) della tessera FITP, la Sua
            registrazione sui canali previsti dalla FITP (siti internet e
            software gestionali), la gestione e la pubblicazione della Sua
            attività sportiva federale in occasione della partecipazione a gare
            e tornei, italiani ed internazionali, o, comunque, della Sua
            attività federale nonché la gestione e la pubblicazione di eventuali
            provvedimenti disciplinari o sanzionatori cui dovesse essere
            sottoposto da parte degli organi di giustizia sportiva. La base
            giuridica su cui si fonda questo trattamento è (i) l’esecuzione di
            un contratto di cui Lei è parte e (ii) l’adempimento da parte della
            FITP ad obblighi legali. <br />
            B. in caso di atleta agonista e di atleta non agonista, consentire
            alla FITP di predisporre delle statistiche relative alle Sue
            prestazioni sportive e di pubblicarle in modo tale da renderle
            visibili rispettivamente anche agli altri atleti agonisti tesserati
            FITP e agli altri atleti non agonisti tesserati FITP. <br />
            C. consentire di farLe avere notizie sempre aggiornate sulle
            attività e le promozioni della FITP, inviandoLe comunicazioni ed
            informazioni di natura commerciale e di marketing diretto sui
            servizi e sulle offerte della FITP, sugli sconti e su ogni altra
            iniziativa promozionale e di fidelizzazione adottata dalla FITP,
            così come per permettere l’invio di newsletter, sia attraverso
            sistemi di contatto tradizionali che totalmente automatizzati.<br />
            D. consentirLe di ricevere offerte, sconti ed ogni altro beneficio e
            iniziativa promozionale modellati sulle Sue specifiche esigenze e
            propensioni di acquisto, anche partecipando a programmi di
            fidelizzazione che prevedono, ai fini del loro funzionamento, la
            registrazione, l’individuazione e la profilazione dei dati relativi
            alle Sue preferenze, abitudini, comportamenti e interessi, desunti,
            ad esempio, dallo svolgimento dell’attività sportiva.<br />
            E. consentire alla FITP di tenerLa sempre aggiornata sulle
            promozioni di Suo interesse, inviando materiale pubblicitario e/o
            comunicazioni e informazioni di natura commerciale e di marketing
            indiretto su servizi e prodotti, relativi ad offerte, sconti e ogni
            altra iniziativa promozionale e di fidelizzazione adottata dai
            nostri partner commerciali, sia attraverso sistemi di contatto
            tradizionali che totalmente automatizzati, come, a mero scopo
            esemplificativo, mediante l’utilizzo del Suo indirizzo di posta
            elettronica. <br />La base giuridica su cui si fondano i trattamenti
            sub B), C), D) e E) è il Suo consenso espresso.
          </span>

          <span class="pb-3">
            <b>2. Periodo di conservazione.</b> Il Titolare del trattamento
            intende conservare i dati personali per un arco di tempo non
            superiore a quello necessario per il conseguimento delle finalità
            per i quali sono trattati, fino ad un massimo di dieci anni dal
            termine dei trattamenti, fatto salvo il caso in cui sorga la
            necessità di mantenerli per adempire ad obblighi di legge, oppure
            per accertare, esercitare o difendere un diritto in sede giudiziaria
            ovvero per esigenze storiche o di archivio In merito alle attività
            di trattamento dei Suoi dati personali per le finalità di marketing
            diretto, la FITP ha stabilito di provvedere alla cancellazione
            automatica dei Suoi dati personali, ovvero alla loro trasformazione
            in forma anonima in modo permanente e non reversibile, entro 24 mesi
            dalla registrazione dei dati raccolti. In merito alle attività di
            trattamento dei Suoi dati personali per le finalità di marketing
            profilato, la FITP ha stabilito di provvedere alla cancellazione
            automatica dei Suoi dati personali, ovvero alla loro trasformazione
            in forma anonima in modo permanente e non reversibile, entro 12 mesi
            dalla registrazione dei dati raccolti.
          </span>

          <span class="pb-3">
            <b>3. Categorie di destinatari.</b>In caso di atleta agonista e di
            atleta non agonista, i dati delle statistiche relative alle Sue
            prestazioni sportive, previo Suo consenso, saranno visibili
            rispettivamente dagli altri atleti agonisti tesserati FITP e dagli
            altri atleti non agonisti FITP. Per quanto riguarda gli ulteriori
            dati personali trattati, gli stessi non saranno oggetto di
            diffusione a terzi. Potranno comunque venire a conoscenza dei dati,
            in relazione alle finalità di trattamento precedentemente
            esposte:<br />
            - i soggetti che possono accedere ai dati in forza di disposizione
            di legge previste dal diritto dell’Unione Europea o da quello dello
            Stato membro cui è soggetto il Titolare del trattamento;<br />
            - i titolari del trattamento facenti parte del gruppo FITP
            esclusivamente per finalità amministrative interne; <br />
            - soggetti che svolgono, all’interno dei confini dell’Unione
            Europea, in totale autonomia, come distinti titolari del
            trattamento, ovvero in qualità di responsabili del trattamento
            all’uopo nominati dalla FITP, finalità ausiliarie alle attività e ai
            servizi di cui al Paragrafo 1, ovvero istituti bancari e
            assicurativi, studi legali e notarili, compagnie assicurative,
            società di recupero crediti, centri di servizio, società o
            consulenti incaricati di fornire ulteriori servizi al Titolare del
            trattamento.
          </span>

          <span class="pb-3">
            <b>4. Trasferimento verso paesi terzi.</b>
            Solo in caso di richiesta di invio della newsletter, il Titolare del
            trattamento trasferirà i Suoi dati personali verso paesi terzi al di
            fuori dell’Unione Europea, compresi gli Stati Uniti d’America, per
            la gestione di tale servizio. Tale trasferimento è sempre soggetto a
            garanzie adeguate ed idonee attraverso l’utilizzo di Clausole
            Contrattuali Standard dell’Unione Europea.
          </span>

          <span class="pb-3">
            <b>5. Processi decisionali automatizzati.</b> . Il Titolare del
            trattamento utilizza processi decisionali automatizzati, ivi
            compresa la profilazione di cui all’art. 22, co. 1 e 4, del
            Regolamento, solo con il suo consenso. Qualora acconsentisse alla
            profilazione, i dati forniti potranno essere utilizzati per
            analizzare o prevedere preferenze, comportamenti e posizioni al fine
            di poter personalizzare il contenuto della comunicazione commerciale
            e offrire solo prodotti ed offerte dedicate in linea con i Suoi
            gusti e le preferenze espresse. Nello specifico, queste attività di
            trattamento prevedono la registrazione, l’analisi e la profilazione
            (i) dei Suoi dati identificativi, (ii) delle informazioni relative
            alla Sua attività sportiva, (iii) nonché dei dati relativi alle Sue
            abitudini e scelte di consumo. L’aggregazione e l’analisi dei dati
            raccolti permettono al Titolare del trattamento di individuare i
            tesserati accomunati da comportamenti di acquisto simili. Grazie ai
            risultati di queste analisi, potrà ricevere proposte commerciali
            funzionali alle Sue esigenze. Il Titolare del trattamento attua
            tutte le misure più appropriate per tutelare i Suoi diritti, le Sue
            libertà e i Suoi legittimi interessi anche all’interno del processo
            di profilazione.
          </span>

          <span class="pb-3">
            <b>6. Natura del conferimento.</b> Il conferimento dei dati
            personali per le finalità di cui al paragrafo 1.A ha natura
            obbligatoria in quanto un Suo eventuale rifiuto a fornire i dati
            personali richiesti comporterebbe l’impossibilità di rilasciarLe (o
            di rinnovarLe) la tessera FITP e di gestire e pubblicare la Sua
            attività federale. Il conferimento dei dati personali per le
            finalità di cui ai paragrafi 1.B, 1.C, 1.D e 1.E ha natura
            facoltativa ma la mancata autorizzazione al loro trattamento non ci
            consentirebbe di creare delle statistiche relative alle Sue
            prestazioni sportive (in caso di atleta agonista e non agonista), di
            farLe fruire a pieno dei vantaggi offerti attraverso informazioni di
            natura pubblicitaria, commerciale e di marketing diretto,
          </span>

          <span class="pb-3">
            <b>7. Diritti dell’Interessato.</b>
            In relazione al trattamento dei dati personali, Lei ha il diritto
            di: revocare il Suo consenso al trattamento, chiedere l’accesso ai
            Suoi dati personali, ottenerne la rettifica e l’integrazione ,
            ottenere la cancellazione dei Suoi dati personali, ottenere la
            limitazione del trattamento dei Suoi dati personali, ricevere i dati
            personali che La riguardano in un formato strutturato, di uso comune
            e leggibile da dispositivo automatico, nonché di trasmettere tali
            dati a un altro titolare del trattamento , opporsi in qualsiasi
            momento, per motivi connessi alla Sua situazione particolare, al
            trattamento dei Suoi dati personali svolto ai sensi dell’art. 6, co.
            1, lettere e) o f) del Regolamento 2016/679, non essere sottoposto a
            decisioni basate unicamente sul trattamento automatizzato. Per
            esercitare ciascuno dei Suoi diritti, può contattare il Titolare del
            trattamento, indirizzando una comunicazione presso la sede legale
            oppure inviando una email all’indirizzo privacy@fitp.it,
            specificando il nome, cognome e indirizzo postale, numero della
            tessera FITP eventualmente posseduta, i dettagli della richiesta.
            Lei ha inoltre diritto di proporre reclamo ad un’autorità di
            controllo oppure adire le opportune sedi giudiziarie, qualora
            ritenga che il trattamento che La riguarda violi il Regolamento.
          </span>
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <div>
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <a
          v-else-if="!hasBacheca"
          :href="redirectInsegnanti"
          class="btn text-hover-primary text-gray-600 fs-5 text-end p-0 m-0"
        >
          <i class="bi bi-house fs-2 text-gray-800"> </i>
        </a>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i>
        </router-link>
      </span>
    </div>
  </template>
</template>

<script>
import { defineComponent, onMounted, ref, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { editPrivacyRequest } from "@/requests/privacyRequest";
import { pdfModuloPrivacy } from "@/requests/downloadFiles";
import TextInput from "@/components/components-fit/utility/inputs/TextInput.vue";
import { useStore } from "vuex";
import { useRedirectInsegnanti } from "@/composables/redirectInsegnanti";

import services from "../../../axios/dbManag";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

import isEnabled from "@/composables/isEnabled.js";

export default defineComponent({
  name: "privacy-persona",
  props: ["persona"],
  components: { TextInput, Loading },
  setup(props) {
    const store = useStore();
    const isLoaded = ref(false);
    const privacy = ref([]);
    const error = ref(false);
    services.dbManag
      .post(`/anagrafiche/persone/privacy/list`, {
        id_persona: props.persona.id,
      })
      .then((res) => {
        privacy.value = { ...res.data.results[0] };
      })
      .catch((e) => {
        error.value = true;
      });

    isLoaded.value = true;

    const id_persona = ref(props.persona.id);
    const info = ref("");

    const privacy_1 = ref(null);
    const privacy_2 = ref(null);
    const privacy_3 = ref(null);
    const privacy_4 = ref(null);
    const privacy_5 = ref(null);

    const isLoading = ref(false);

    const editPrivacy = async () => {
      isLoading.value = true;
      let messaggioResponse;
      messaggioResponse = "Consensi modificati correttamente";
      await editPrivacyRequest(
        id_persona.value,
        privacy_1.value,
        privacy_2.value,
        privacy_3.value,
        privacy_4.value,
        privacy_5.value,
        info.value
      )
        .then((value) => {
          if (value.status == 200) {
            Swal.fire({
              html: messaggioResponse,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          } else {
            // const strMsg = ref(
            //   "<b>Si è verificato un errore</b><br>Riprovare più tardi<br><br>"
            // );
            Swal.fire({
              html: value.data.message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
            services.dbManag
              .post(`/anagrafiche/persone/privacy/list`, {
                id_persona: props.persona.id,
              })
              .then((res) => {
                privacy.value = { ...res.data.results[0] };
              })
              .catch((e) => console.log("errore fetch privacy: " + e));
          }
        })
        .catch((e) => {
          const strMsg = ref(
            "<b>Si è verificato un errore</b><br>Riprovare più tardi<br><br>"
          );
          Swal.fire({
            html: strMsg.value,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
          services.dbManag
            .post(`/anagrafiche/persone/privacy/list`, {
              id_persona: props.persona.id,
            })
            .then((res) => {
              privacy.value = { ...res.data.results[0] };
            })
            .catch((e) => console.log("errore fetch privacy: " + e));
        });
      isLoading.value = false;
    };

    const stampaModulo = async () => {
      isLoading.value = true;
      await pdfModuloPrivacy(null, id_persona.value, "P");
      isLoading.value = false;
      // .then((res) => {
      //   isLoading.value = false;
      //   if (res.status != 200) {
      //     alertFailed(
      //       res.data.message
      //         ? res.data.message
      //         : "Attenzione! Si è verificato un errore. Riprovare più tardi"
      //     );
      //   }
      // });
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Privacy Persona", [
        "Lista Persone",
        "Dettaglio Persona",
      ]);
    });

    const { pagesLength, redirectInsegnanti, menuLoaded, hasBacheca } =
      useRedirectInsegnanti();

    return {
      privacy,
      isLoaded,
      editPrivacy,
      id_persona,
      info,
      privacy_1,
      privacy_2,
      privacy_3,
      privacy_4,
      privacy_5,
      isLoading,
      stampaModulo,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
      error,

      hasBacheca,
      pagesLength,
      redirectInsegnanti,
      menuLoaded,

      isEnabled,
    };
  },
});
</script>

<style scoped>
.form-control {
  height: 28px !important;
}

.form-control-sm {
  width: 5%;
}
.form-select {
  height: 28px;
}
</style>

<style>
div.col-2.h-28 > div > div > div > input {
  height: 28px !important;
}

div > span > div > div > div > input {
  height: 28px !important;
}
</style>
